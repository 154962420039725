* {
    box-sizing: border-box;
    font-family: "Graphik-Regular", sans-serif;
}

html,
body {
    margin: 0;
    padding: 0;
}

.screenHeight {
    height: 100vh;
}

.fullWidth {
    width: 100%;
}

.fullHeight {
    height: 100%;
}

// Text classes
.textEllipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

// CSS for plaid iframe to load on the right side instead of the default behaviour of occupying the whole screen.
iframe[id^="plaid-link-iframe-"]{
    max-width: 939px;
    margin-left: auto;
}
